/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("vehicles/AddNewVehicleLength", item)
        .then(response => {
          commit("ADD_VehicleLength", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/vehicles/GetAllVehiclesLengths")
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehicleLength", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .put("/vehicles/UpdateVehicleLength", item)
        .then(response => {
          debugger
          commit("UPDATE_VehicleLength", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteVehicleLength({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/DeleteVehicleLength?Id="+ item.ID)
        .then(response => {
          debugger
          commit("REMOVE_ITEM", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


};
